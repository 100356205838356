import { forkJoin, from, Observable, of, timer } from "rxjs";
import { tap, debounce, switchMap, filter, repeat, delay, map, debounceTime } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TEmailCompositionForm, EmailCompositionForm } from "../../../../domain/codecs/form/EmailCompositionForm";
import { EmailAttachmentSearchForm } from "../../../../domain/codecs/form/EmailAttachmentSearchForm";
import { task } from "fp-ts";
import { DetailedEmailForm, TDetailedEmailForm } from "../../../../domain/codecs/form/TriageForm";
import { EmailAttachments } from "../../../../domain/codecs/EmailComposition";
import { Address1ToCopyText } from "../../../../domain/codecs/util/Address1ToCopyText";
import { TSessionFeatureToggleForm } from "../../../../domain/codecs/form/SessionFeatureToggleForm";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { Routes } from "../router/routerRoutes";
import { DateTime } from "luxon";

export const actions: TActionsDefinitionsList = [
    action("CRM_TYPE_CASE_SEARCH_TEXT", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            tap((searchString) => setState(lens.global_legal.cases_search.edited.searchString.set(searchString))),
            debounce(() => timer(200)),
            tap(() => setState(lens.global_legal.cases_search.status.set("submitting"))),
            switchMap(() => formOperation("CasesSearch", lens.global_legal.cases_search.get()(getState()))),
            tap((response) => setState(lens.global_legal.cases_search.children.set(response.children))),
        ).subscribe();
    }),
    action("CRM_COMPOSE_EMAIL_TYPE_CASE_SEARCH_TEXT", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            tap((searchString) => setState(lens.global_legal.compose_email.children.cases_search_form.edited.searchString.set(searchString))),
            debounce(() => timer(200)),
            tap(() => setState(lens.global_legal.compose_email.children.cases_search_form.status.set("submitting"))),
            switchMap(() => formOperation("CasesSearch", lens.global_legal.compose_email.children.cases_search_form.get()(getState()))),
            tap((response) => setState(lens.global_legal.compose_email.children.cases_search_form.children.set(response.children))),
        ).subscribe();
    }),
    action("CRM_GET_EMAIL_COMPOSITION_CASE_ATTACHMENTS", (obs$: Observable<TEmailCompositionForm>, lens, setState, getState) => {
        obs$.pipe(
            filter((composeEmailForm) => !!composeEmailForm.edited.caseID),
            tap(() => setState(lens.global_legal.compose_email.children.email_attachments_search_form.status.set("submitting"))),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.edited.case_id.set(form.edited.caseID ?? ""))),
            switchMap(
                (form) => form.edited.caseID !== null ? 
                formOperation("GetCaseEmailAttachmentOptions", lens.global_legal.compose_email.children.email_attachments_search_form.get()(getState())) 
                : task.of(EmailAttachmentSearchForm.newDefault())()
            ),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.set(form))),
        ).subscribe();
    }),
    action("CRM_SET_CASE_FOR_COMPOSE_EMAIL_FORM", (obs$: Observable<TEmailCompositionForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.global_legal.compose_email.edited.subject.set(
                getSubjectLine(lens.global_legal.compose_email.get()(getState()), form.edited.caseID)
            ))),
            tap((form) => setState(lens.global_legal.compose_email.edited.caseID.set(form.edited.caseID))),
            tap(() => setState(lens.global_legal.compose_email.edited.sentFrom.set(sessionUser.email))),
            tap(() => setState(lens.global_legal.compose_email.edited.attachments.set(EmailAttachments.newDefault()))),
            tap(() => setState(lens.global_legal.compose_email.children.email_attachments_search_form.status.set("submitting"))),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.edited.case_id.set(form.edited.caseID ?? ""))),
            switchMap(
                (form) => form.edited.caseID !== null ? 
                    formOperation("GetCaseEmailAttachmentOptions", lens.global_legal.compose_email.children.email_attachments_search_form.get()(getState())) 
                    : task.of(EmailAttachmentSearchForm.newDefault())()
            ),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.set(form))),
        ).subscribe();
    }),
    action("CRM_UPDATE_COMPOSE_EMAIL_FORM", (obs$: Observable<TEmailCompositionForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.global_legal.compose_email.set(form))),
        ).subscribe();
    }),
    action("CRM_SEND_NEW_EMAIL", (obs$: Observable<TEmailCompositionForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.global_legal.compose_email.set({ ...form, status: "submitting" }))),
            switchMap(() => formOperation("SendNewLegalEmail", lens.global_legal.compose_email.get()(getState()))),
            tap((form) => setState(lens.global_legal.compose_email.set(form))),
        ).subscribe();
    }),
    action("CRM_SEND_REPLY_EMAIL", (obs$: Observable<TEmailCompositionForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.global_legal.compose_email.set({ ...form, status: "submitting" }))),
            switchMap(() => formOperation("ReplyLegalEmail", lens.global_legal.compose_email.get()(getState()))),
            tap((form) => setState(lens.global_legal.compose_email.set(form))),
        ).subscribe();
    }),
    action("CRM_SEND_FORWARD_EMAIL", (obs$: Observable<TEmailCompositionForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.global_legal.compose_email.set({ ...form, status: "submitting" }))),
            switchMap(() => formOperation("ForwardLegalEmail", lens.global_legal.compose_email.get()(getState()))),
            tap((form) => setState(lens.global_legal.compose_email.set(form))),
        ).subscribe();
    }),
    action("CRM_RESET_COMPOSE_EMAIL_FORM", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.global_legal.compose_email.set(EmailCompositionForm.newDefault()))),
            tap(() => setState(lens.global_legal.attached_email.set(DetailedEmailForm.newDefault()))),
        ).subscribe();
    }),
    action("CRM_COMPOSE_EMAIL_REPLY", (obs$: Observable<TDetailedEmailForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.global_legal.attached_email.set(payload))),
            tap((payload) => setState(lens.global_legal.compose_email.set(payload.children.reply_form))),
        ).subscribe();
    }),
    action("CRM_OPEN_COMPOSE_EMAIL", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.global_legal.compose_email_is_open.set(true))),
            // Get email attachments, if a compose email has been cached
            map(() => lens.global_legal.compose_email.get()(getState())),
            filter((composeEmailForm) => !!composeEmailForm.edited.caseID),
            tap(() => setState(lens.global_legal.compose_email.children.email_attachments_search_form.status.set("submitting"))),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.edited.case_id.set(form.edited.caseID ?? ""))),
            switchMap(
                (form) => form.edited.caseID !== null ? 
                formOperation("GetCaseEmailAttachmentOptions", lens.global_legal.compose_email.children.email_attachments_search_form.get()(getState())) 
                : task.of(EmailAttachmentSearchForm.newDefault())()
            ),
            tap((form) => setState(lens.global_legal.compose_email.children.email_attachments_search_form.set(form))),
        ).subscribe()
    }),
    action("CRM_CLOSE_COMPOSE_EMAIL", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.global_legal.compose_email_is_open.set(false)))
        ).subscribe()
    }),
    action("CRM_COMPOSE_EMAIL_FORWARD", (obs$: Observable<TDetailedEmailForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.global_legal.attached_email.set(payload))),
            tap((payload) => setState(lens.global_legal.compose_email.set(payload.children.forward_form))),
        ).subscribe();
    }),
    action("FIRST_LOAD", (obs$: Observable<null>, lens, setState, getState, route) => {
        forkJoin([
            startGetUnresolvedInboxCount$(obs$, lens, route, setState, getState),
            copyEnabledFeatureTogglesFromGlobal$(obs$, lens, setState),
            setWeeklyKPISIsShowingIfApplicable$(obs$, lens, setState),
        ]).subscribe();
    }),
    action("CRM_SESSION_FEATURE_TOGGLE_CHANGE", (obs$: Observable<TSessionFeatureToggleForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.session_feature_toggle_form.set(payload))),
            debounceTime(1000),
            switchMap(() => formOperation("UpdateSessionFeatureToggles", lens.session_feature_toggle_form.get()(getState()))),
            tap((response) => setState(lens.session_feature_toggle_form.set(response))),
        ).subscribe();
    }),
    action("CRM_CLOSE_WEEKLY_KPIS", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.weekly_kpi_is_showing.set(false))),
            tap(() => localStorage.setItem("weekly_kpis_last_seen", DateTime.utc().setZone("Europe/London").toISODate())),
        ).subscribe();
    }),
];

const startGetUnresolvedInboxCount$ = (
    obs$: Observable<null>,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    route: Routes,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        filter(() =>
            sessionUser.user_role === "admin"
            || sessionUser.user_role === "sail_legal_authoriser_staff"
            || sessionUser.user_role === "sail_legal_non_authoriser_staff"
        ),
        // We have to create another observable to be able to repeat, we need to also be careful when we make the request to get the count and not do it
        // when a user is in the process of logging in or submitting their two-factor auth code as the 401 from the below request would cause an endless loop.
        switchMap(() =>
            of(undefined)
                .pipe(
                    switchMap(() =>
                        route.active !== "VIEW_AUTH_2FA"
                        && route.active !== "VIEW_MAGIC_LINK_RESEND"
                        && route.active !== "VIEW_MAGIC_LINK_RESENT"
                            ? from(formOperation("GetUnresolvedInboxCount", lens.inbox_count_form.get()(getState())))
                                .pipe(
                                    tap((response) => setState(lens.inbox_count_form.set(response))),
                                )
                            : of(undefined)
                    ),
                    delay(60000),
                    repeat(),
                )
        ),
    );

const copyEnabledFeatureTogglesFromGlobal$ = (
    obs$: Observable<null>,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    setState: TCodecSetState,
) =>
    obs$.pipe(
        tap(() => setState(lens.session_feature_toggle_form.edited.enabled_feature_toggles.set(sessionUser.enabled_feature_toggles))),
        tap(() => setState(lens.session_feature_toggle_form.original.enabled_feature_toggles.set(sessionUser.enabled_feature_toggles))),
    );

const setWeeklyKPISIsShowingIfApplicable$ = (
    obs$: Observable<null>,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    setState: TCodecSetState,
) =>
    obs$.pipe(
        map(() => ({
            lastSeenDateTime:
                localStorage.getItem("weekly_kpis_last_seen")
                && DateTime.fromISO(localStorage.getItem("weekly_kpis_last_seen") || "").isValid
                    ? DateTime.fromISO(localStorage.getItem("weekly_kpis_last_seen") || "")
                    : null,
            dayOfWeek: DateTime.utc().setZone("Europe/London").toFormat("c"),
        })),
        filter(({lastSeenDateTime, dayOfWeek}) =>
            dayOfWeek === "1" // Monday
            && (
                lastSeenDateTime === null
                || DateTime.utc().setZone("Europe/London").diff(lastSeenDateTime, ["days"]).days >= 7
            )
        ),
        tap(() => setState(lens.weekly_kpi_is_showing.set(true))),
    );

const getSubjectLine = (form: TEmailCompositionForm, newCaseId: string | null): string  => {
    // cached case - address, klyant matter id and introducer ref
    const cachedCaseAddressString = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === form.edited.caseID)?.addresses
        .map((address) => Address1ToCopyText(address))
        .join(" & ")
    ;
    const cachedCaseKlyantMatterId = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === form.edited.caseID)?.klyant_matter_id
    ;
    const cachedCaseKlyantMatterIdString = cachedCaseKlyantMatterId !== ""
        ? `Matter id: ${cachedCaseKlyantMatterId} - `
        : ""
    ;
    const cachedCaseIntroducerExternalReferenceId = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === form.edited.caseID)?.introducer_external_reference_id
    ;
    const cachedCaseIntroducerExternalReferenceIdString = cachedCaseIntroducerExternalReferenceId !== ""
        ? `Your ref: ${cachedCaseIntroducerExternalReferenceId} - `
        : ""
    ;
    
    // new case - address, klyant matter id and introducer ref
    const newCaseAddressString = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === newCaseId)?.addresses
        .map((address) => Address1ToCopyText(address))
        .join(" & ")
    ;
    const newCaseKlyantMatterId = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === newCaseId)?.klyant_matter_id
    ;
    const newCaseKlyantMatterIdString = newCaseKlyantMatterId !== ""
        ? `Matter id: ${newCaseKlyantMatterId} - `
        : ""
    ;
    const newCaseIntroducerExternalReferenceId = form.children.cases_search_form.children
        .find((legalCase) => legalCase.id === newCaseId)?.introducer_external_reference_id
    ;
    const newCaseIntroducerExternalReferenceIdString = newCaseIntroducerExternalReferenceId !== ""
        ? `Your ref: ${newCaseIntroducerExternalReferenceId} - `
        : ""
    ;

    const newRefsAndAddressString = `${newCaseIntroducerExternalReferenceIdString}${newCaseKlyantMatterIdString}${newCaseAddressString}`;
    const cachedRefsAndAddressString = `${cachedCaseIntroducerExternalReferenceIdString}${cachedCaseKlyantMatterIdString}${cachedCaseAddressString}`;

    if (
        !!newCaseAddressString 
        && (
            form.edited.subject === ""
            || cachedCaseAddressString === form.edited.subject
            || cachedRefsAndAddressString === form.edited.subject 
        )
    ) {
        return newRefsAndAddressString;
    };

    if (!newCaseId
        && (
            cachedCaseAddressString === form.edited.subject
            || cachedRefsAndAddressString === form.edited.subject
        )
     ) {
        return "";
    };
    
    return form.edited.subject
};